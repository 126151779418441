@charset "UTF-8";
/* =============================================================================


     font-face


============================================================================= */
@font-face {
  font-family: "tsukuB";
  src: url("../font/B.woff") format("woff"), url("../font/B.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "tsukuD";
  src: url("../font/D.woff") format("woff"), url("../font/D.otf") format("opentype");
  font-display: swap;
}

/* =============================================================================


     font-extend


============================================================================= */
.form-item {
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  font-style: normal;
}

/***********************
   基本サイズ
***********************/
/* =============================================================================


   order


============================================================================= */
.mv {
  position: relative;
  overflow: hidden;
  min-height: 700px;
  height: 50vw;
  max-height: 100vh;
  background: url(../img/lp/mv.jpg);
  background-position: right;
  background-size: cover;
}

@media (max-width: 767px) {
  .mv {
    min-width: unset;
    height: 400px;
    min-height: unset;
    background-image: url(../img/lp/mv-sp.jpg);
  }
}

.mv-ttl {
  position: absolute;
  top: 30%;
  left: 10%;
  transform: translateY(-50%);
  max-width: 1000px;
  width: 40vw;
  flex-direction: column;
}

@media (max-width: 767px) {
  .mv-ttl {
    background: rgba(255, 255, 255, 0.6);
    width: 100%;
    z-index: 1;
    padding: 16px 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
  }
  .mv-ttl img {
    margin: auto;
    width: 160px;
  }
}

/* =============================================================================


     slide


============================================================================= */
.slider {
  background: url(../img/lp/bg01.jpg) no-repeat;
  background-size: cover;
}

.slide-txtbox {
  text-orientation: upright;
  margin: 70px auto 0;
  writing-mode: vertical-rl;
}

/* **************************************


     first


***************************************/
.first,
.secand,
.third,
.two,
.slider {
  padding: 80px 0px;
  overflow-x: hidden;
}

@media (max-width: 767px) {
  .first,
  .secand,
  .third,
  .two,
  .slider {
    padding: 30px 0;
  }
}

@media (max-width: 1365px) {
  .first .inner,
  .two .inner {
    padding: 0;
  }
}

@media (max-width: 767px) {
  .first .inner,
  .two .inner {
    padding: 0 16px;
  }
}

.first-container {
  position: relative;
  margin: 0 calc(50% - 50vw);
  width: 100vw;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

@media screen and (max-width: 1399px) {
  .first-container {
    width: 100%;
    margin: auto;
  }
}

@media (max-width: 767px) {
  .first-container {
    flex-direction: column;
  }
}

.first-txtbox,
.secand-txtbox,
.third-txtbox,
.two-txtbox,
.delivery-txtbox {
  text-orientation: upright;
  writing-mode: vertical-rl;
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 40px;
}

@media (max-width: 1365px) {
  .first-txtbox,
  .secand-txtbox,
  .third-txtbox,
  .two-txtbox,
  .delivery-txtbox {
    padding: 40px 20px;
  }
}

@media (max-width: 767px) {
  .first-txtbox,
  .secand-txtbox,
  .third-txtbox,
  .two-txtbox,
  .delivery-txtbox {
    width: 100%;
    padding: 40px 0;
    justify-content: center;
  }
}

.first-txtbox {
  background-image: url(../img/lp/bg02.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

@media (max-width: 767px) {
  .first-txtbox:before {
    background-image: url(../img/lp/bg02.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100%;
  }
}

.secand-txtbox {
  justify-content: flex-end;
}

.order-ttl {
  display: inline-block;
  font-size: 3.2rem;
  font-weight: bold;
  position: relative;
  margin-left: 40px;
}

@media (max-width: 1365px) {
  .order-ttl {
    font-size: 2.8rem;
    margin-left: 24px;
    line-height: 1.3;
  }
}

@media (max-width: 767px) {
  .order-ttl {
    font-size: 2.2rem;
    margin-left: 20px;
  }
}

.order-txt {
  font-size: 2rem;
}

@media (max-width: 767px) {
  .order-txt {
    font-size: 1.4rem;
    position: relative;
  }
}

.first-img,
.third-img,
.delivery-img,
.two-img {
  width: 50%;
}

.first-img img,
.third-img img,
.delivery-img img,
.two-img img {
  height: 100%;
  object-fit: cover;
  font-family: "object-fit:cover";
}

@media (max-width: 767px) {
  .first-img,
  .third-img,
  .delivery-img,
  .two-img {
    width: 100%;
    text-align: center;
  }
}

.secand {
  position: relative;
}

.secand-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row-reverse;
}

@media (max-width: 767px) {
  .secand-container {
    flex-direction: column;
    align-items: center;
  }
}

.third {
  background-image: url(../img/lp/bg03.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.third-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

@media (max-width: 767px) {
  .third-container {
    flex-direction: column;
  }
}

.video-container {
  width: 50%;
}

@media (max-width: 767px) {
  .video-container {
    width: 100%;
  }
}

.video-box {
  width: 690px;
  position: relative;
}

.video-box:before {
  content: "";
  display: block;
  padding-top: 70%;
  /* 高さを幅の75%に固定 */
}

.video-box iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

@media (max-width: 767px) {
  .video-box {
    width: 100%;
  }
}

/* =============================================================================


     two


============================================================================= */
.two {
  background-image: url(../img/lp/bg01.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
}

.two-container {
  position: relative;
  margin: 0 calc(50% - 50vw);
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.two-container:nth-child(1) {
  flex-direction: row-reverse;
}

.two-container:nth-child(1) .two-txtbox {
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .two-container:nth-child(1) .two-txtbox {
    justify-content: center;
  }
}

@media screen and (max-width: 1399px) {
  .two-container {
    margin: auto;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .two-container {
    width: 100%;
    flex-direction: column-reverse;
    align-items: center;
  }
  .two-container:nth-child(1) {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.two-txtbox {
  padding-top: 80px;
  padding-bottom: 80px;
}

.order-link {
  margin-right: 40px;
}

.order-link a {
  transition: 0.2s;
  display: inline-block;
}

.order-link a:hover {
  opacity: 0.7;
}

/* =============================================================================


     .delivery


============================================================================= */
@media (max-width: 767px) {
  .delivery {
    padding: 60px 0;
  }
}

.delivery-container {
  display: flex;
  justify-content: space-between;
}

.delivery-container:nth-child(1) {
  flex-direction: row-reverse;
}

@media (max-width: 767px) {
  .delivery-container:nth-child(1) {
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .delivery-container {
    flex-direction: column;
  }
}

.yen-txtbox {
  padding: 2em 0;
  width: 49%;
}

@media (max-width: 767px) {
  .yen-txtbox {
    width: 100%;
    padding: 40px 0;
  }
}

.yen-ttl {
  padding: 1em 0;
  font-size: 2.4rem;
  font-weight: bold;
  width: 80%;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

@media (max-width: 767px) {
  .yen-ttl {
    font-size: 2rem;
  }
}

.yen-txt {
  margin-top: 40px;
  font-size: 2rem;
}

@media (max-width: 767px) {
  .yen-txt {
    font-size: 1.5rem;
  }
}

/* =============================================================================


     sacefy


============================================================================= */
.safety-img {
  width: 100%;
}

@media (max-width: 767px) {
  .safety-img {
    min-height: 300px;
  }
  .safety-img img {
    min-height: 300px;
    object-fit: cover;
    font-family: "object-fit:cover";
  }
}

.safety-txtbox {
  text-orientation: upright;
  writing-mode: vertical-rl;
  margin: 120px auto 80px;
  min-height: 1000px;
}

@media (max-width: 767px) {
  .safety-txtbox {
    writing-mode: horizontal-tb;
    margin: 60px auto;
    min-height: unset;
  }
  .safety-txtbox .order-ttl {
    margin-bottom: 32px;
    margin-left: 0;
  }
}

.safety-txtbox .order-txt {
  margin-top: 2em;
}

@media (max-width: 767px) {
  .safety-txtbox .order-txt {
    margin-top: 0;
  }
}

/* =============================================================================


     contact


============================================================================= */
.contact {
  background-image: url(../img/lp/bg01.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80px 0;
}

.contact-txt {
  font-size: 3rem;
  text-align: center;
  margin-top: 50px;
}

@media (max-width: 767px) {
  .contact-txt {
    font-size: 1.6rem;
    margin-top: 32px;
  }
}

.contact-ttl {
  text-align: center;
  margin: auto;
  width: 6em;
  font-size: 6rem;
  line-height: 1;
}

@media (max-width: 767px) {
  .contact-ttl {
    font-size: 4rem;
  }
}

.form {
  margin: 120px auto;
}

@media (max-width: 767px) {
  .form {
    margin: 60px auto;
  }
}

.form-item {
  align-items: flex-start;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 30px 0;
}

.form-item._textarea {
  align-items: flex-start;
}

@media (max-width: 767px) {
  .form-item {
    align-items: center;
    flex-direction: column;
  }
  .form-item._textarea {
    align-items: center;
  }
}

.form-item dt {
  align-items: center;
  display: flex;
  font-size: 2.4rem;
  justify-content: space-between;
  min-width: 295px;
}

@media (max-width: 767px) {
  .form-item dt {
    display: block;
    font-size: 1.8rem;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    min-width: unset;
  }
}

.form-item dt.number {
  flex-direction: column;
  align-items: flex-start;
}

.form-item dt p {
  font-size: 80%;
}

.form-item dt span {
  border: 1px solid #c1272d;
  color: #c1272d;
  display: inline-block;
  font-size: 1.6rem;
  height: 25px;
  line-height: 25px;
  margin-left: auto;
  text-align: center;
  width: 62px;
}

@media (max-width: 767px) {
  .form-item dt span {
    margin-left: 2em;
  }
}

.form-item dd {
  margin-left: 5em;
  width: calc(100% - 295px);
  font-size: 1.8rem;
}

@media (max-width: 767px) {
  .form-item dd {
    margin-left: 0;
    margin-top: 30px;
    width: 100%;
  }
}

.form-item dd.name {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin-left: 2.5em;
}

@media (max-width: 767px) {
  .form-item dd.name {
    margin-left: 0;
  }
}

.form-item dd.name span {
  display: block;
  font-size: 1.9rem;
  font-weight: bold;
  text-align: center;
  width: 5em;
}

@media (max-width: 767px) {
  .form-item dd.name span {
    font-size: 1.4rem;
  }
}

.wpcf7-list-item + .wpcf7-list-item {
  margin-left: 2em;
}

@media (max-width: 767px) {
  .wpcf7-list-item + .wpcf7-list-item {
    margin: 0.25em 0 0;
  }
}

.select .wpcf7-list-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.select .wpcf7-list-item select {
  padding: 0.25em;
}

@media (max-width: 767px) {
  .select .wpcf7-list-item {
    flex-direction: column;
    align-items: center;
  }
}

.select-span {
  margin-left: 60px;
  color: #51535b;
}

@media (max-width: 767px) {
  .select-span {
    margin: 20px auto 0;
    font-size: 1.4rem;
  }
}

.form-btn-container {
  margin-top: 100px;
}

@media (max-width: 767px) {
  .form-btn-container {
    margin-top: 40px;
  }
}
